import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { fetchAuthSession } from 'aws-amplify/auth';
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, ScanCommand, PutCommand } from "@aws-sdk/lib-dynamodb";

function CreateUserForm({ show, onHide, onSubmit, currentUser }) {
  const [organization, setOrganization] = useState('');
  const [username, setUsername] = useState('');
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [newProject, setNewProject] = useState('');
  const [addProjectError, setAddProjectError] = useState('');
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [newOrganization, setNewOrganization] = useState('');
  const [addOrgError, setAddOrgError] = useState('');
  const [permissions, setPermissions] = useState(['PutFile']);

const orgDatabaseTable = process.env.REACT_APP_ORG_DATABASE_TABLE
const projectDatabaseTable = process.env.REACT_APP_PROJECT_DATABASE_TABLE

  useEffect(() => {
    if (show) {
      fetchOrganizations();
      fetchProjects();
    }
  }, [show]);

  const fetchOrganizations = async () => {
    try {
      const { credentials } = await fetchAuthSession();
      
      const client = new DynamoDBClient({ 
        region: "us-west-2",
        credentials: credentials
      });
      const docClient = DynamoDBDocumentClient.from(client);

      const command = new ScanCommand({
        TableName: orgDatabaseTable,
      });

      const response = await docClient.send(command);
      const orgNames = response.Items.map(item => item.name);
      setOrganizations(orgNames);
      
      if (orgNames.length > 0) {
        setOrganization(orgNames[0]);
      }
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  const fetchProjects = async () => {
    try {
      const { credentials } = await fetchAuthSession();
      
      const client = new DynamoDBClient({ 
        region: "us-west-2",
        credentials: credentials
      });
      const docClient = DynamoDBDocumentClient.from(client);

      const command = new ScanCommand({
        TableName: projectDatabaseTable,
      });

      const response = await docClient.send(command);
      const projectNames = response.Items.map(item => item.name);
      setProjects(projectNames.map(project => ({ value: project, label: project })));
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const handleAddProject = async () => {
    if (!/^[a-z0-9_]+$/.test(newProject)) {
      setAddProjectError('Project name should be lowercase, with underscores. No spaces.');
      return;
    }

    setIsLoading(true);
    setAddProjectError('');

    try {
      const { credentials } = await fetchAuthSession();
      
      const client = new DynamoDBClient({ 
        region: "us-west-2",
        credentials: credentials
      });
      const docClient = DynamoDBDocumentClient.from(client);

      const command = new PutCommand({
        TableName: projectDatabaseTable,
        Item: {
          projectId: 0,
          name: newProject
        }
      });

      await docClient.send(command);

      // Update projects list
      setProjects([...projects, { value: newProject, label: newProject }]);
      setNewProject('');
      setShowAddProjectModal(false);
    } catch (error) {
      console.error('Error adding new project:', error);
      setAddProjectError('Failed to add project. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleProjectChange = (selectedOptions) => {
    setSelectedProjects(selectedOptions);
  };

  const handleAddOrganization = async () => {
    if (!/^[a-zA-Z0-9_]+$/.test(newOrganization)) {
      setAddOrgError('Organization name should contain only letters, numbers, and underscores. No spaces.');
      return;
    }
  
    setIsLoading(true);
    setAddOrgError('');
  
    try {
      const { credentials } = await fetchAuthSession();
      
      const client = new DynamoDBClient({ 
        region: "us-west-2",
        credentials: credentials
      });
      const docClient = DynamoDBDocumentClient.from(client);
  
      const command = new PutCommand({
        TableName: orgDatabaseTable,
        Item: {
          organizationId: 0,
          name: newOrganization
        }
      });
  
      await docClient.send(command);
  
      // Update organizations list
      setOrganizations([...organizations, newOrganization]);
      setOrganization(newOrganization);
      setNewOrganization('');
      setShowAddOrgModal(false);
    } catch (error) {
      console.error('Error adding new organization:', error);
      setAddOrgError('Failed to add organization. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePermissionChange = (perm) => {
    if (perm === 'PutFile') return; // PutFile is always selected
    setPermissions(prev => 
      prev.includes(perm) 
        ? prev.filter(p => p !== perm) 
        : [...prev, perm]
    );
  };

  // const handleSubmit = async (e) => {
  //   console.log(selectedProjects)
  //   e.preventDefault();
  //   setIsLoading(true);
    
  //   try {
  //     await onSubmit({
  //       organization,
  //       username,
  //       projects: selectedProjects.map(option => option.value).join(','),
  //       applicationuser: currentUser.signInDetails.loginId,
  //       permissions: permissions
  //     });
  //     onHide();
  //   } catch (error) {
  //     console.error('Error submitting form:', error);
  //     // You might want to show an error message to the user here
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    console.log("Selected Projects:", selectedProjects);
    console.log("Organization:", organization);
    console.log("Username:", username.toLowerCase());
    console.log("Permissions:", permissions);
    console.log("Current User:", currentUser);
  
    const postData = {
      organization: organization,
      username: username.toLowerCase(),
      projects: selectedProjects.map(option => option.value).join(','),
      applicationuser: currentUser?.signInDetails?.loginId,
      permissions: permissions,
      action: 'create'
    };
  
    console.log("Data being sent to onSubmit:", postData);
  
    try {
      await onSubmit(postData);
      onHide();
    } catch (error) {
      console.error('Error submitting form:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* Start Create User Modal */}
      {/*<Modal show={show} onHide={onHide} backdrop={showAddProjectModal || showAddOrgModal ? 'static' : true} keyboard={!showAddProjectModal && !showAddOrgModal}>*/}
      <Modal 
        show={show} 
        onHide={onHide} 
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={!showAddProjectModal}>
          <Modal.Title>Create New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Organization</Form.Label>
              <Form.Select value={organization} onChange={(e) => setOrganization(e.target.value)} required>
                {organizations.map((org, index) => (
                  <option key={index} value={org}>{org}</option>
                ))}
              </Form.Select>
            </Form.Group>
            {/* Add organization button */}
            <Button variant="secondary" onClick={() => setShowAddOrgModal(true)} disabled={isLoading} className="mb-3">
            Add Organization
            </Button>
            <Form.Group className="mb-3">
              <Form.Label>Username (*lowercase email)</Form.Label>
              <Form.Control type="email" value={username} onChange={(e) => setUsername(e.target.value)} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>File Permissions</Form.Label>
              <div className="mb-2 text-warning">
                <strong>Important:</strong> Users should only be given additional file permissions if they explicitly ask.
              </div>
              {['PutFile', 'DeleteFile', 'DownloadFile'].map((perm) => (
                <Form.Check 
                  key={perm}
                  type="checkbox"
                  id={`permission-${perm}`}
                  label={perm}
                  checked={permissions.includes(perm)}
                  onChange={() => handlePermissionChange(perm)}
                  disabled={perm === 'PutFile'}
                />
              ))}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Projects</Form.Label>
              <Select
                isMulti
                name="projects"
                options={projects}
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedProjects}
                onChange={handleProjectChange}
                isDisabled={isLoading || showAddProjectModal || showAddOrgModal}
              />
            </Form.Group>
            <Button variant="secondary" onClick={() => setShowAddProjectModal(true)} disabled={isLoading}>
              Add Project
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={isLoading || showAddProjectModal || showAddOrgModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={isLoading || showAddProjectModal || showAddOrgModal}>
          {isLoading ? (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span className="ms-2">Creating...</span>
            </>
          ) : (
            'Create User'
          )}
        </Button>
        </Modal.Footer>
      </Modal>
      {/* END Create User Modal */}

      {/* Start Add Organization Modal */}
      <Modal show={showAddOrgModal} onHide={() => setShowAddOrgModal(false)} backdrop="static" keyboard={false} style={{ zIndex: 1070 }}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Organization Name</Form.Label>
            <Form.Control
              type="text"
              value={newOrganization}
              onChange={(e) => setNewOrganization(e.target.value)}
              placeholder="Enter organization name"
            />
            <Form.Text className="text-muted">
            Organization should be lowercase, with underscores '_' instead of spaces.
            </Form.Text>
            {addOrgError && <div className="text-danger">{addOrgError}</div>}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddOrgModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddOrganization} disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="ms-2">Adding...</span>
              </>
            ) : (
              'Add Organization'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Add Organization Modal */}

      {/* Add Project Modal */}
      <Modal show={showAddProjectModal} onHide={() => setShowAddProjectModal(false)} backdrop="static" keyboard={false} style={{ zIndex: 1060 }}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Project Name</Form.Label>
            <Form.Control
              type="text"
              value={newProject}
              onChange={(e) => setNewProject(e.target.value)}
              placeholder="Enter project name"
            />
            <Form.Text className="text-muted">
              Project should be lowercase, with underscores '_' and no spaces.
            </Form.Text>
            {addProjectError && <div className="text-danger">{addProjectError}</div>}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddProjectModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddProject} disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="ms-2">Adding...</span>
              </>
            ) : (
              'Add Project'
            )}
          </Button>
        </Modal.Footer>
      </Modal>\
      {/* End Add Project Modal */}
    </>
  );
}

export default CreateUserForm;