import React, { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { View, Heading, Button, Text, Flex } from '@aws-amplify/ui-react';
import axios from 'axios';
import { Modal, Form , Spinner } from 'react-bootstrap';
import { FaFolder } from 'react-icons/fa';
import Select from 'react-select';
import { DynamoDBClient, ScanCommand , QueryCommand } from '@aws-sdk/client-dynamodb';
import { DynamoDBDocumentClient } from '@aws-sdk/lib-dynamodb';

const apiUrl = process.env.REACT_APP_API_URL;
const projectDatabaseTable = process.env.REACT_APP_PROJECT_DATABASE_TABLE;


function FancyFilePath({ path }) {
    return (
        <View className="path-container">
            <FaFolder className="folder-icon" />
            <Text className="path-text">{path}</Text>
        </View>
    );
}

function renderPermissions(permissions) {
    const allPermissions = ['PutFile', 'DeleteFile', 'DownloadFile'];
    return (
        <View className="user-permissions">
            <Heading level={4}>User Permissions</Heading>
            {allPermissions.map(perm => {
                const hasPermission = permissions.includes(perm);
                return (
                    <View key={perm} className="permission-item">
                        {hasPermission ? (
                            <Text color="green">✅ {perm}</Text>
                        ) : (
                            <Text color="red">❌ {perm}</Text>
                        )}
                    </View>
                );
            })}
        </View>
    );
}

function UserInfo({ user, updateUserDetails, onUserDeleted }) {
    const [isEmailLoading, setIsEmailLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [showEmailConfirm, setShowEmailConfirm] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [updateResponseMessage, setUpdateResponseMessage] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [logData, setLogData] = useState([]);
    const [isLogDataLoading, setIsLogDataLoading] = useState(false);

    useEffect(() => {
        if (user && user.userFilePermissions) {
            setSelectedPermissions(user.userFilePermissions);
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            fetchLogData(user.username);
        }
    }, [user]);
    
    useEffect(() => {
        let responseTimer;
        let updateResponseTimer;

        if (responseMessage) {
            responseTimer = setTimeout(() => {
                setResponseMessage('');
            }, 5000);
        }

        if (updateResponseMessage) {
            updateResponseTimer = setTimeout(() => {
                setUpdateResponseMessage('');
            }, 5000);
        }
        return () => {
            clearTimeout(responseTimer);
            clearTimeout(updateResponseTimer);
        }
    }, [responseMessage, updateResponseMessage]);

    useEffect(() => {
        if (showUpdateModal && user) {
            fetchProjects();
            setSelectedProjects(user.homeDirectoryDetails.map(detail => ({
                value: detail.Entry.slice(1),
                label: detail.Entry.slice(1),
            })));
            setSelectedPermissions(prevPerms => {
                let newPerms = Array.isArray(user.userFilePermissions) 
                    ? user.userFilePermissions 
                    : [];
                if (!newPerms.includes('PutFile')) {
                    newPerms.push('PutFile');
                }
                return newPerms;
            });
        }
    }, [showUpdateModal, user]);

    const fetchProjects = async () => {
        try {
            const { credentials } = await fetchAuthSession();
            const client = new DynamoDBClient({
                region: 'us-west-2',
                credentials: credentials
            });
            const docClient = DynamoDBDocumentClient.from(client);
    
            const command = new ScanCommand({
                TableName: projectDatabaseTable,
            });
    
            const response = await docClient.send(command);
            const projectNames = response.Items.map(item => item.name.S);
            setProjects(projectNames.map(project => ({ value: project, label: project })));
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };
    
    const handlePermissionChange = (perm) => {
        if (perm === 'PutFile') return; // PutFile is always selected
        setSelectedPermissions(prev =>
            prev.includes(perm)
                ? prev.filter(p => p !== perm)
                : [...prev, perm]
        );
    };

    const invokeLambda = async (operation, action = null) => {
        if (!user) return;
        const setLoading = operation === 'email' ? setIsEmailLoading : setIsDeleteLoading;
        setLoading(true);
        setResponseMessage('');
        try {
            const fullApiUrl = `${apiUrl}/${operation}user`;
            const requestData = {
                'username': user.username,
                'action': action
            };
            const { tokens } = await fetchAuthSession();
            const idToken = tokens.idToken.toString();
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': idToken,
            };

            const response = await axios.post(fullApiUrl, requestData, { headers });
            console.log(`Lambda invocation succeeded:`, response.data);
            setResponseMessage(response.data.message || 'Operation completed successfully');
            if (operation !== 'delete') {
                updateUserDetails(user.username);
            } else {
                onUserDeleted();
            }
        } catch (error) {
            console.error(`Error invoking ${operation} lambda:`, error);
            setResponseMessage(error.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleEmailClick = () => {
        setShowEmailConfirm(true);
    };

    const handleDeleteClick = () => {
        setShowDeleteConfirm(true);
    };

    const confirmEmail = () => {
        setShowEmailConfirm(false);
        const action = user.notified ? 'reset' : 'initial';
        invokeLambda('email', action);
    };

    const confirmDelete = () => {
        setShowDeleteConfirm(false);
        invokeLambda('delete');
    };

    const handleUpdate = async () => {
        console.log(selectedPermissions)
        setIsUpdateLoading(true);

        try {
            const fullApiUrl = `${apiUrl}/createuser`;
            const { tokens } = await fetchAuthSession();
            const idToken = tokens.idToken.toString();
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': idToken,
            };
            
    
            const response = await axios.post(fullApiUrl, {
                username: user.username,
                projects: selectedProjects.map(option => option.value).join(','),
                permissions: selectedPermissions,
                action: 'update',
            }, { headers });
    
            setUpdateResponseMessage(response.data.message || 'User updated successfully');
            setTimeout(() => {
                setShowUpdateModal(false);
                setUpdateResponseMessage('');
                updateUserDetails(user.username);
            }, 1500);
        } catch (error) {
            console.error('Error updating user:', error);
            setUpdateResponseMessage(error.response?.data?.message || 'An error occurred while updating the user');
        } finally {
            setIsUpdateLoading(false);
        }
    };

    const fetchLogData = async (username) => {
        setIsLogDataLoading(true);
        try {
            const { credentials } = await fetchAuthSession();
            const client = new DynamoDBClient({
                region: 'us-west-2',
                credentials: credentials
            });
            const docClient = DynamoDBDocumentClient.from(client);

            const command = new QueryCommand({
                TableName: 'transfer-family-log',
                KeyConditionExpression: 'username = :username',
                ExpressionAttributeValues: {
                    ':username': { S: username }
                },
                ScanIndexForward: false, // To get the most recent logs first
                Limit: 5 // Limit to the 10 most recent logs
            });

            const response = await docClient.send(command);
            const formattedLogs = response.Items.map(item => ({
                project: item.project?.S || 'N/A',
                filename: item.filename.S,
                fileSize: (item.fileSize.N / (1024 * 1024)).toFixed(2), // Convert to MB
                timestamp: new Date(item.timestamp.S).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                })
            }));
            setLogData(formattedLogs);
        } catch (error) {
            console.error('Error fetching log data:', error);
            setLogData([])
        } finally {
            setIsLogDataLoading(false);
        }
    };


    if (!user) return <Text>Please Select a User From the Table</Text>;

    return (
        <View className="user-info">
            <Heading level={3}>User Information</Heading>
            <p><strong>Username:</strong> {user.username}</p>
            <p><strong>Created At:</strong> {user.timestamp}</p>
            <p>
                <strong>Emailed Instructions?</strong>{' '}
                {user.notified ? (
                    <span style={{ color: 'green' }}>✅ Yes</span>
                ) : (
                    <span style={{ color: 'red' }}>❌ No</span>
                )}
            </p>
            <p><strong>Last Emailed At:</strong> {user.lastEmailedAt}</p>
            <p><strong>Last Password Reset:</strong> {user.lastPasswordReset}</p>
            <p><strong>Organization:</strong> {user.organization}</p>
            <p><strong>Policy ARN:</strong> {user.policyArn}</p>
            <p><strong>Role ARN:</strong> {user.roleArn}</p>
            {user.homeDirectoryDetails && user.homeDirectoryDetails.length > 0 && (
                <View className="home-directory-details">
                    <Heading level={4}>AWS S3 Directory Details</Heading>
                    {user.homeDirectoryDetails.map((detail, index) => (
                        <View key={index} className="directory-mapping">
                            <View className="path-entry">
                                <Text className="path-label"><strong>Entry (What user sees):</strong></Text>
                                <FancyFilePath path={detail.Entry} />
                            </View>
                            <View className="path-entry">
                                <Text className="path-label"><strong>Target (Actual filepath in AWS S3):</strong></Text>
                                <FancyFilePath path={detail.Target} />
                            </View>
                        </View>
                    ))}
                </View>
            )}
            {user.userFilePermissions && (
                renderPermissions(user.userFilePermissions)
            
            )}
            <View className="user-logs" marginTop="20px">
                <Heading level={4}>Recent File Transfers</Heading>
                {isLogDataLoading ? (
                    <Text> Loading log data...</Text>
                ) : logData.length > 0 ? (
                    <table style={{width: '100%', borderCollapse: 'collapse', marginTop: '10px'}}>
                        <thead>
                            <tr style={{backgroundColor: '#f2f2f2'}}>
                                <th style={{padding: '8px', textAlign: 'left'}}>Project</th>
                                <th style={{padding: '8px', textAlign: 'left'}}>Filename</th>
                                <th style={{padding: '8px', textAlign: 'left'}}>Size (MB)</th>
                                <th style={{padding: '8px', textAlign: 'left'}}>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logData.map((log, index) => (
                                <tr key={index} style={{borderBottom: '1px solid #ddd'}}>
                                    <td style={{padding: '8px'}}>{log.project}</td>
                                    <td style={{padding: '8px'}}>{log.filename}</td>
                                    <td style={{padding: '8px'}}>{log.fileSize}</td>
                                    <td style={{padding: '8px'}}>{log.timestamp}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <Text>No recent file transfers found for this user.</Text>
                )}
            </View>
            <Flex className="button-container">
                <Button
                    onClick={handleEmailClick}
                    marginRight="10px"
                    isLoading={isEmailLoading}
                    loadingText="Sending..."
                    isDisabled={isDeleteLoading}
                >
                    {user.notified ? 'Send Password Reset Email' : 'Send Initial Instructions Email'}
                </Button>
                <Button
                    onClick={handleDeleteClick}
                    marginRight="10px"
                    isLoading={isDeleteLoading}
                    loadingText="Deleting..."
                    isDisabled={isEmailLoading}
                >
                    Delete User
                </Button>
                <Button
                    onClick={() => setShowUpdateModal(true)}
                    isDisabled={isEmailLoading || isDeleteLoading}
                >
                    Update User Details
                </Button>
            </Flex>
            {(isEmailLoading || isDeleteLoading) && (
                <Text marginTop="20px">Processing request...</Text>
            )}
            {responseMessage && (
                <Text
                    marginTop="20px"
                    padding="10px"
                    backgroundColor={responseMessage.includes('error') ? 'var(--amplify-colors-red-10)' : 'var(--amplify-colors-green-10)'}
                    color={responseMessage.includes('error') ? 'var(--amplify-colors-red-80)' : 'var(--amplify-colors-green-80)'}
                    borderRadius="4px"
                >
                    {responseMessage}
                </Text>
            )}

            <Modal show={showEmailConfirm} onHide={() => setShowEmailConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Email Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to {user.notified ? 'reset password for' : 'send initial instructions to'} {user.username}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEmailConfirm(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={confirmEmail}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete {user.username}? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirm(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form.Label>Projects</Form.Label>
                    <Select
                      isMulti
                      name="projects"
                      options={projects}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedProjects}
                      onChange={setSelectedProjects}
                    />
                    <Form.Group className="mt-3">
                        <Form.Label>File Permissions</Form.Label>
                        <div className="mb-2 text-warning">
                            <strong>Important:</strong> Users should only be given additional file permissions if they explicitly ask.
                        </div>
                        {['PutFile', 'DeleteFile', 'DownloadFile'].map((perm) => (
                            <Form.Check 
                                key={perm}
                                type="checkbox"
                                id={`permission-${perm}`}
                                label={
                                    <span>
                                        {selectedPermissions.includes(perm) ? '✅' : '❌'} {perm}
                                    </span>
                                }
                                checked={selectedPermissions.includes(perm)}
                                onChange={() => handlePermissionChange(perm)}
                                disabled={perm === 'PutFile'}
                            />
                        ))}
                    </Form.Group>
                    {updateResponseMessage && (
                      <Text
                        marginTop="10px"
                        padding="10px"
                        backgroundColor={updateResponseMessage.includes('Error') ? 'var(--amplify-colors-red-10)' : 'var(--amplify-colors-green-10)'}
                        color={updateResponseMessage.includes('Error') ? 'var(--amplify-colors-red-80)' : 'var(--amplify-colors-green-80)'}
                        borderRadius="4px"
                      >
                        {updateResponseMessage}
                      </Text>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUpdateModal(false)} disabled = {isUpdateLoading}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleUpdate} disabled = {isUpdateLoading}>
                        {isUpdateLoading ? (
                            <>
                            <Spinner as = "span" animation="border" size="sm" role = "status" aria-hidden="true"/>
                            <span className = "ms-2">Updating...</span>
                            </>
                        ) : (
                            'Update'
                        )}
                        
                    </Button>
                </Modal.Footer>
            </Modal>
        </View>
    );
}

export default UserInfo;