import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, ScanCommand, GetCommand } from "@aws-sdk/lib-dynamodb";
import { withAuthenticator, Table, TableCell, TableBody,
   TableHead, TableRow, ThemeProvider,
    View, Heading, Button, Pagination,
     TextField,
    defaultTheme, Text, Image} from '@aws-amplify/ui-react';
import axios from 'axios';
import awsmobile from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
//import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateUserForm from './CreateUserForm';
//import { FaFolder } from 'react-icons/fa';
import jgreLogo from './assets/jg-research-evalutation-logo-v2.svg';
import UserInfo from './UserInfo';
const apiUrl = process.env.REACT_APP_API_URL
const userDatabaseTable = process.env.REACT_APP_USER_DATABASE_TABLE


Amplify.configure(awsmobile);

function ExistingUsersTable({ onSelectUser, selectedUser }) {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ field: 'username', direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    try {
      const { credentials } = await fetchAuthSession();
      const client = new DynamoDBClient({
        region: "us-west-2",
        credentials: credentials
      });
      const docClient = DynamoDBDocumentClient.from(client);
      const command = new ScanCommand({
        TableName: process.env.REACT_APP_USER_DATABASE_TABLE,
      });
      const response = await docClient.send(command);
      setUsers(response.Items);
    } catch (error) {
      console.error('Error fetching DynamoDB data:', error);
    }
  }

  const getSortedData = () => {
    let sortedUsers = [...users].filter(user =>
      user.username.toLowerCase().includes(filter.toLowerCase()) ||
      user.organization?.toLowerCase().includes(filter.toLowerCase())
    );

    if (sortConfig.field) {
      sortedUsers.sort((a, b) => {
        if (a[sortConfig.field] < b[sortConfig.field]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.field] > b[sortConfig.field]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return sortedUsers;
  };

  const getPaginatedData = () => {
    const sortedData = getSortedData();
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedData.slice(startIndex, startIndex + itemsPerPage);
  };

  const handleSort = (field) => {
    setSortConfig((prevConfig) => ({
      field,
      direction: prevConfig.field === field && prevConfig.direction === 'ascending' ? 'descending' : 'ascending',
    }));
  };

  return (
    <View>
      <TextField
        label="Search for users"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        style={{ marginBottom: '20px' }}
      />
      <Table
        highlightOnHover={true}
        variation="striped"
        className="table-container"
      >
        <TableHead>
          <TableRow>
            <TableCell as="th" onClick={() => handleSort('username')}>
              Username {sortConfig.field === 'username' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </TableCell>
            <TableCell as="th" onClick={() => handleSort('organization')}>
              Organization {sortConfig.field === 'organization' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getPaginatedData().map(user => (
            <TableRow
              key={user.username}
              onClick={() => onSelectUser(user)}
              backgroundColor={selectedUser && selectedUser.username === user.username ? 'highlight' : undefined}
            >
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.organization}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(getSortedData().length / itemsPerPage)}
        onNext={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(getSortedData().length / itemsPerPage)))}
        onPrevious={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
        onChange={setCurrentPage}
      />
    </View>
  );
}


// function ExistingUsersTable({ onSelectUser, selectedUser }) {
//   const [users, setUsers] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [filter, setFilter] = useState('');
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
//   //const [selectedUsername, setSelectedUsername] = useState(null);

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   useEffect(() => {
//     let sortedUsers = [...users].filter(user =>
//       user.username.toLowerCase().includes(filter.toLowerCase()) ||
//       user.organization?.toLowerCase().includes(filter.toLowerCase())
//     );

//     if (sortConfig.key) {
//       sortedUsers.sort((a, b) => {
//         if (a[sortConfig.key] < b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? -1 : 1;
//         }
//         if (a[sortConfig.key] > b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? 1 : -1;
//         }
//         return 0;
//       });
//     }

//     setFilteredUsers(sortedUsers);
//   }, [users, filter, sortConfig]);

//   useEffect(() => {
//     if (selectedUser) {
//       setSelectedUsername(selectedUser.username);
//     } else {
//       setSelectedUsername(null);
//     }
//   }, [selectedUser]);

//   async function fetchUsers() {
//     try {
//       const { credentials } = await fetchAuthSession();
      
//       const client = new DynamoDBClient({ 
//         region: "us-west-2",
//         credentials: credentials
//       });
//       const docClient = DynamoDBDocumentClient.from(client);

//       const command = new ScanCommand({
//         TableName: userDatabaseTable,
//       });

//       const response = await docClient.send(command);
//       setUsers(response.Items);
//     } catch (error) {
//       console.error('Error fetching DynamoDB data:', error);
//     }
//   }

//   const requestSort = (key) => {
//     let direction = 'ascending';
//     if (sortConfig.key === key && sortConfig.direction === 'ascending') {
//       direction = 'descending';
//     }
//     setSortConfig({ key, direction });
//   };

//   const handleSelectUser = (user) => {
//     setSelectedUsername(user.username);
//     onSelectUser(user);
//   };

//   return (
//     <View>
//       <TextField
//         label="Search for users"
//         value={filter}
//         onChange={(e) => setFilter(e.target.value)}
//         style={{ marginBottom: '20px' }}
//       />
//       <Table 
//         highlightOnHover={true} 
//         variation="striped"
//         className="table-container"
//       >
//         <TableHead>
//           <TableRow>
//             <TableCell 
//               as="th"
//               onClick={() => requestSort('username')}
//             >
//               Username {sortConfig.key === 'username' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
//             </TableCell>
//             <TableCell 
//               as="th"
//               onClick={() => requestSort('organization')}
//             >
//               Organization {sortConfig.key === 'organization' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
//             </TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {filteredUsers.map(user => (
//             <TableRow
//               key={user.username}
//               onClick={() => onSelectUser(user)}
//               backgroundColor={selectedUser && selectedUser.username === user.username ? 'highlight' : undefined}
//             >
//               <TableCell>{user.username}</TableCell>
//               <TableCell>{user.organization}</TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </View>
//   );
// }


const App = ({ signOut, user }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [showCreateForm, setShowCreateForm] = useState(false);

  const ResponseMessage = ({ message, type }) => (
    <View
      style={{
        padding: '10px',
        marginBottom: '20px',
        borderRadius: '4px',
        backgroundColor: type === 'error' ? '#ffebee' : '#e8f5e9',
        color: type === 'error' ? '#c62828' : '#2e7d32',
      }}
    >
      <Text>{message}</Text>
    </View>
  );

  const onUserDeleted = () => {
    // Show a success message
    setResponseMessage('User successfully deleted');
  
    // Set a short timeout to allow the success message to be seen
    setTimeout(() => {
      // Refresh the page
      window.location.reload();
    }, 1500); // 1.5 seconds delay
  };

  
  const fetchUser = async (username) => {
    try {
      const { credentials } = await fetchAuthSession();
      
      const client = new DynamoDBClient({ 
        region: "us-west-2", // replace with your region
        credentials: credentials
      });
      const docClient = DynamoDBDocumentClient.from(client);

      const command = new GetCommand({
        TableName: userDatabaseTable, // replace with your actual table name
        Key: {
          username: username
        }
      });

      const response = await docClient.send(command);
      if (response.Item) {
        setSelectedUser(response.Item);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const updateUserDetails = (username) => {
    fetchUser(username);
  };

  const handleCreateUser = async (userData) => {
    console.log('userData received:', userData);  // Add this line
    try {
      const fullApiUrl = `${apiUrl}/createuser`;
      const { tokens } = await fetchAuthSession();
      const idToken = tokens.idToken.toString();
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': idToken,
      };

      const response = await axios.post(fullApiUrl, {
        ...userData,
        action: 'create',
        projects: userData.projects,
      }, { headers });

      console.log('User created:', response.data);
      setResponseMessage(response.data.message || 'User created successfully');
      // Refresh the user list or add the new user to the existing list
      // Set a timeout to allow the success message to be seen
      setTimeout(() => {
        // Refresh the entire application
        window.location.reload();
      }, 1500); // 1.5 seconds delay

    } catch (error) {
      console.error('Error creating user:', error);
      setResponseMessage(error.response?.data?.message || 'An error occurred while creating the user');
    }
  };



 return (
  <ThemeProvider theme={defaultTheme}>
      <View className="page-container">
        <View className="header">
          <View className="logo-and-title">
            <Image
              src={jgreLogo}
              alt="JGRE Logo"
              className="logo"
            />
            <Heading level={1} className="title">S3 User Management</Heading>
          </View>
          <Button onClick={signOut} className="logout-button">
            Logout
          </Button>
        </View>
        <View className="container">
          {responseMessage && (
            <ResponseMessage 
              message={responseMessage} 
              type={responseMessage.includes('error') ? 'error' : 'success'}
            />
          )}
          <View className="content-container">
            <View className="left-container">
              <ExistingUsersTable onSelectUser={setSelectedUser} selectedUser={selectedUser} />
              <br />
              <Button onClick={() => setShowCreateForm(true)} marginBottom="20px">
                Create New User
              </Button>
            </View>
            <View className="right-container">
              <UserInfo 
                user={selectedUser} 
                updateUserDetails={updateUserDetails} 
                onUserDeleted={onUserDeleted}
              />
            </View>
          </View>
          <CreateUserForm 
            show={showCreateForm}
            onHide={() => setShowCreateForm(false)}
            onSubmit={(userData) => handleCreateUser(userData, user)}
            currentUser={user}
          />
        </View>
      </View>
    </ThemeProvider>
  );
};

export default withAuthenticator(App, {hideSignUp: true});